.background-inscription {
  @apply  bg-[#F3F3F3]
}

.div-input-inscription {
  @apply flex flex-col mt-3 w-full md:w-2/3 lg:w-[60%]
}

.label-inscription {
  @apply text-[#000] text-base font-dmsans font-medium
}

.input-inscription {
  @apply text-[#000] text-sm font-dmsans outline outline-[1px] outline-[#737373]/80 focus:outline-[#D1E9FF] h-8 rounded-lg pl-3 mt-1 w-full
}

.form-inscription {
  @apply bg-white flex flex-col justify-center items-center rounded-[20px] w-full  p-5 mt-5 md:mt-2 lg:mt-0 shadow-lg shadow-[#D1E9FF]
}

.titre-inscription {
  @apply text-black text-2xl font-semibold font-dmsans
}

.submit-inscription {
  @apply bg-[#EE1B22] text-white text-base font-dmsans font-semibold w-2/3 md:w-2/3 lg:w-[60%] p-2 rounded-lg mt-5
}

.footer-inscription {
  @apply text-[#98A2B3] text-base mb-5 font-dmsans
}

.footer-inscription button {
  @apply text-[#1570EF] font-dmsans
}

.div-password {
  @apply relative
}

.toggle-password {
  @apply absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-[#98A2B3]
}

.custom-dialog-container {
  @apply w-[50%] max-h-[90%] overflow-auto
}
